<template>
  <div :class="['preloader', { preloader_hidden: !isPreloader }]">
    <div class="preloader__ellipsis">
      <div class="preloader__ellipse" />
      <div class="preloader__ellipse" />
      <div class="preloader__ellipse" />
      <div class="preloader__ellipse" />
    </div>
  </div>

  <metainfo>
    <template v-slot:link="{ content }">
      {{ content() }}
    </template>
  </metainfo>

  <router-view
    :class="routerViewClasses"
    @showPreloader="isPreloader = true"
    @hidePreloader="isPreloader = false"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mixinTheme from "@/mixins/theme";
import mixinFontScaling from "@/mixins/font-scaling";

import GET_LANGUAGES from "@/gql/query/dictionary/getLanguages.gql";
import GET_CURRENCIES from "@/gql/query/dictionary/getCurrencies.gql";
import GET_PUBLISHED_MENUS from "@/gql/query/getPublishedMenus.gql";
import GET_RESTAURANT_BY_ID from "@/gql/query/getRestaurantById.gql";
import GET_RESTAURANT_BY_CODE from "@/gql/query/getRestaurantByCode.gql";
import PREVIEW_EVENT from "@/gql/query/previewEvent.gql";
import PREVIEW_MENU from "@/gql/query/previewMenu.gql";

export default {
  metaInfo() {
    return {
      title: this.restaurant ? this.restaurant.name : "...",
      link: [
        {
          rel: "icon",
          href: this.getHrefIcon,
        },
        {
          rel: "stylesheet",
          href: this.getHrefFontPrimary,
        },
        {
          rel: "stylesheet",
          href: this.getHrefFontSecondary,
        },
      ],
    };
  },
  mixins: [mixinTheme, mixinFontScaling],
  data() {
    return {
      isPreloader: true,
    };
  },
  computed: {
    ...mapGetters({
      themes: "themes",
      restaurant: "restaurant",
      getRestaurantRequestData: "getRestaurantRequestData",
      getMenusRequestData: "getMenusRequestData",
      session: "analytics/session",
    }),
  },
  watch: {
    async $route(newRouteValue, oldRouteValue) {
      if (oldRouteValue.name !== undefined) {
        return;
      }

      // only on init

      this.setIsBuiltInView(newRouteValue.query["built-in-view"] === "1");

      if (this.isEveryStyleValueInQuery) {
        this.setStyleFromQuery();
      } else {
        this.setStyleFromStore();
      }

      if (newRouteValue.name === "index") {
        this.isPreloader = false;
      } else {
        try {
          const responses = await Promise.all([
            this.setupRestaurant(newRouteValue),
            this.setupMenus(newRouteValue),
            this.$apollo.query({ query: GET_LANGUAGES }),
            this.$apollo.query({ query: GET_CURRENCIES }),
          ]);

          this.setLanguages(responses[2].data.getLanguages);
          this.setCurrencies(responses[3].data.getCurrencies);

          if (!this.session.id) {
            const locale = this.restaurant.primaryLanguage;
            this.setLocale(locale);
            this.$i18n.locale = locale;
          } else {
            const languages = [
              this.restaurant.primaryLanguage,
              ...this.restaurant.translationLanguages,
            ];
            if (!languages.find((i) => i === this.$i18n.locale)) {
              const locale = this.restaurant.primaryLanguage;
              this.setLocale(locale);
              this.$i18n.locale = locale;
            }
          }

          if (newRouteValue.params.mode !== "preview") {
            this.setupAnalytics();
          }
        } catch (error) {
          this.$router.push({ name: "error" });
        } finally {
          this.isPreloader = false;
        }
      }
    },
  },
  methods: {
    ...mapActions({
      setIsBuiltInView: "setIsBuiltInView",
      setRestaurant: "setRestaurant",
      setMenus: "setMenus",
      setLanguages: "setLanguages",
      setCurrencies: "setCurrencies",
      setupAnalytics: "analytics/setupAnalytics",
      setGetRestaurantRequestData: "setGetRestaurantRequestData",
      setGetMenusRequestData: "setGetMenusRequestData",
      setLocale: "setLocale",
    }),
    async setupRestaurant(route) {
      if (route.params.mode === "preview") {
        this.setGetRestaurantRequestData({
          query: GET_RESTAURANT_BY_ID,
          variables: { restaurantId: route.query["restaurant"] },
          responseDataField: "getRestaurantById",
        });
      } else {
        this.setGetRestaurantRequestData({
          query: GET_RESTAURANT_BY_CODE,
          variables: { code: route.params.mode },
          responseDataField: "getRestaurantByCode",
        });
      }

      try {
        const response = await this.$apollo.query({
          query: this.getRestaurantRequestData.query,
          variables: {
            ...this.getRestaurantRequestData.variables,
            language: this.session.id ? this.$i18n.locale : null,
            autoTranslateLanguage: null,
          },
        });

        const restaurant =
          response.data[this.getRestaurantRequestData.responseDataField];
        this.setRestaurant(restaurant);

        if (!this.isEveryStyleValueInQuery) {
          this.setStyleFromResponse(restaurant);
        }
      } catch (error) {
        throw Error(error);
      }
    },
    async setupMenus(route) {
      if (route.params.mode === "preview") {
        if (route.query["menu"]) {
          this.setGetMenusRequestData({
            query: PREVIEW_MENU,
            variables: { menuId: route.query["menu"] },
            responseDataField: "previewMenu",
          });
        }
        if (route.query["event"]) {
          this.setGetMenusRequestData({
            query: PREVIEW_EVENT,
            variables: { eventId: route.query["event"] },
            responseDataField: "previewEvent",
          });
        }
      } else {
        this.setGetMenusRequestData({
          query: GET_PUBLISHED_MENUS,
          variables: { code: route.params.mode },
          responseDataField: "getPublishedMenus",
        });
      }

      try {
        const response = await this.$apollo.query({
          query: this.getMenusRequestData.query,
          variables: {
            ...this.getMenusRequestData.variables,
            language: this.session.id ? this.$i18n.locale : null,
            autoTranslateLanguage: null,
          },
        });

        this.setMenus(
          response.data[this.getMenusRequestData.responseDataField],
        );
      } catch (error) {
        throw Error(error);
      }
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/app.sass"
@import "@/assets/styles/theme.sass"
</style>
