import { v4 as uuidv4 } from "uuid";
import { stringifyOrderItem } from "@/order";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    items(state) {
      return state.items;
    },
  },
  mutations: {
    setOrderItems(state, data) {
      state.items = data;
    },
  },
  actions: {
    addToOrderItem({ getters, commit, dispatch }, data) {
      const toCompare = stringifyOrderItem(data);
      const orderItem = getters.items.find(
        (i) => stringifyOrderItem(i) === toCompare,
      );

      if (orderItem) {
        dispatch("updateOrderItemQuantity", {
          id: orderItem.id,
          isAdd: true,
        });
      } else {
        commit("setOrderItems", [
          ...getters.items,
          {
            id: uuidv4(),
            quantity: 1,
            ...data,
          },
        ]);
      }
    },
    removeOrderItem({ getters, commit }, data) {
      commit(
        "setOrderItems",
        getters.items.filter((i) => i.id !== data),
      );
    },
    updateOrderItemQuantity({ getters, commit }, data) {
      commit(
        "setOrderItems",
        getters.items.map((i) =>
          i.id === data.id
            ? { ...i, quantity: data.isAdd ? i.quantity + 1 : i.quantity - 1 }
            : i,
        ),
      );
    },
  },
};
